<template>
  <div>
    <!-- 导航栏 -->
    <div class="nav-bar">
      <!-- 折叠按钮 -->
      <div class="hambuger-container" @click="trigger">
        <i :class="isFold" />
      </div>
      <!-- 面包屑导航 -->
      <el-breadcrumb>
        <el-breadcrumb-item v-for="item of breadcrumbList" :key="item.path">
          <span v-if="item.redirect">{{ item.name }}</span>
          <router-link v-else :to="item.path">{{ item.name }}</router-link>
        </el-breadcrumb-item>
      </el-breadcrumb>
      <!-- 右侧菜单 -->
      <div class="right-menu">
        <!-- 全屏按钮 -->
        <div class="screen-full" @click="fullScreen">
          <i class="iconfont el-icon-myicwindowzoom48px" />
        </div>
        <!-- 用户选项 -->
        <el-dropdown @command="handleCommand">
          <el-avatar :size="40" :src="this.$store.state.user.avatar" />
          <i class="el-icon-caret-bottom" />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="setting">
              <i class="el-icon-s-custom" />个人中心
            </el-dropdown-item>
            <el-dropdown-item command="logout" divided>
              <i class="iconfont el-icon-mytuichu" />退出登录
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <!-- 历史标签栏 -->
    <div class="tabs-view-container">
      <scroll-pane ref="scrollPane" class="tabs-view-wrapper" @scroll="handleScroll">
        <router-link
          v-for="tab of this.$store.state.tab.tabList"
          ref="tab"
          :key="tab.path"
          :class="isActive(tab)"
          :to="{ path: tab.path}"
          tag="span"
          class="tabs-view-item"
          @contextmenu.prevent.native="openMenu(tab,$event)"
        >
            {{ tab.name }}
          <span v-if="isAffix(tab)" class="el-icon-close" @click.prevent.stop="removeTab(tab)" />
        </router-link>
      </scroll-pane>
      <ul v-show="visible" :style="{left:left+'px',top:top+'px'}" class="contextmenu">
          <li @click="goTo(selectedTab)"><i class="el-icon-refresh-right"></i> 刷新页面</li>
          <li v-if="isAffix(selectedTab)" @click="removeTab(selectedTab)"><i class="el-icon-close"></i> 关闭当前</li>
          <li @click="closeOthersTabs(selectedTab)"><i class="el-icon-circle-close"></i> 关闭其他</li>
          <li v-if="!isFirstView(selectedTab)" @click="closeLeftTabs(selectedTab)"><i class="el-icon-back"></i> 关闭左侧</li>
          <li v-if="!isLastView(selectedTab)" @click="closeRightTabs(selectedTab)"><i class="el-icon-right"></i> 关闭右侧</li>
          <li @click="closeAllTab()"><i class="el-icon-circle-close"></i> 全部关闭</li>
      </ul>
    </div>
  </div>
</template>

<script>
import ScrollPane from './ScrollPane'
export default {
  components: { ScrollPane },
  created() {
    //替换面包屑导航
    let matched = this.$route.matched.filter(item => item.name);
    const first = matched[0];
    if (first && first.name !== "首页") {
      matched = [{ path: "/", name: "首页" }].concat(matched);
    }
    this.breadcrumbList = matched;
    //保存当前页标签
    this.$store.dispatch("tab/saveTab", this.$route);
  },
  data: function() {
    return {
      isSearch: false,
      fullscreen: false,
      breadcrumbList: [],
      visible: false,
      top: 0,
      left: 0,
      selectedTab: {},
      affixTabs: []
    };
  },
  methods: {
    goTo(tab) {
      //跳转标签
      if(tab.path===this.$route.path){
        this.$router.push({ path: tab.path })
      }else{
        this.$router.replace({ path: tab.path });
      }
    },
    removeTab(tab) {
      //删除标签
      this.$store.dispatch("tab/removeTab", tab);
      //如果删除的是当前页则返回上一标签页
      if (tab.path === this.$route.path) {
        let tabList = this.$store.state.tab.tabList;
        this.$router.push({ path: tabList[tabList.length - 1].path });
      }
    },
    trigger() {
      this.$store.dispatch("app/toggleSideBar");
    },
    handleCommand(command) {
      if (command === "setting") {
        this.$router.push({ path: "/setting" });
      }
      if (command === "logout") {
        this.$cookie.delete('username');
        this.$cookie.delete('password');
        // 清空用户信息
        this.$store.dispatch("AdminLogout");
        this.$router.push({ path: "/login" });
      }
    },
    closeAllTab() {
      this.$store.dispatch("tab/resetTab");
      this.$router.push({ path: "/" });
    },
    fullScreen() {
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },
    //新
    isFirstView(tab) {
      try {
        let index = this.$store.state.tab.tabList.findIndex(item => item.path === tab.path)
        return index<=1
      } catch (err) {
        return false
      }
    },
    isLastView(tab) {
      try {
        let index = this.$store.state.tab.tabList.findIndex(item => item.path === tab.path)
        return index===this.$store.state.tab.tabList.length-1
      } catch (err) {
        return false
      }
    },
    moveToCurrentTab() {
      const tabs = this.$refs.tab
      this.$nextTick(() => {
        for (const tab of tabs) {
          if (tab.to.path === this.$route.path) {
            this.$refs.scrollPane.moveToTarget(tab)
            break
          }
        }
      })
    },
    closeRightTabs(tab) {
      this.$store.dispatch("tab/removeRightTabs",tab);
      let index = this.$store.state.tab.tabList.findIndex(item => item.path === this.$route.path)
      if(index===-1){
        this.goTo(tab)
      }
      this.moveToCurrentTab()
    },
    closeLeftTabs(tab) {
      this.$store.dispatch("tab/removeLeftTabs",tab);
      let index = this.$store.state.tab.tabList.findIndex(item => item.path === this.$route.path)
      if(index===-1){
        this.goTo(tab)
      }
      this.moveToCurrentTab()
    },
    closeOthersTabs(tab) {
      this.$store.dispatch("tab/resetTab",tab);
      if(this.$route.path!==tab.path){
        this.goTo(tab)
      }
      this.moveToCurrentTab()
    },
    openMenu(tab, e) {
      const menuMinWidth = 105
      const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
      const offsetWidth = this.$el.offsetWidth // container width
      const maxLeft = offsetWidth - menuMinWidth // left boundary
      const left = e.clientX  + 15 // 15: margin right


      if (left > maxLeft) {
        this.left = maxLeft
      } else {
        this.left = left
      }

      this.top = e.clientY
      this.visible = true
      this.selectedTab = tab
    },
    isAffix(tab) {
      return tab.path !== '/'
    },
    closeMenu() {
      this.visible = false
    },
    handleScroll() {
      this.closeMenu()
    }
  },
  computed: {
    //标签是否处于当前页
    isActive() {
      return function(tab) {
        if (tab.path === this.$route.path) {
          return "active";
        }
      };
    },
    isFold() {
      return this.$store.state.app.collapse ? "el-icon-s-unfold" : "el-icon-s-fold";
    }
  },
  watch: {
    visible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu)
      } else {
        document.body.removeEventListener('click', this.closeMenu)
      }
    }
  }
};
</script>

<style scoped>
.nav-bar {
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 30px;
  height: 50px;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}
.hambuger-container {
  font-size: 1.25rem;
  cursor: pointer;
  margin-right: 24px;
}

.right-menu {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.el-icon-caret-bottom {
  margin-left: 0.5rem;
  font-size: 0.75rem;
}
.screen-full {
  cursor: pointer;
  margin-right: 1rem;
  font-size: 1.25rem;
}
</style>
<style lang="scss" scoped>
.tabs-view-container {
  height: 33px;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #d8dce5;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .12), 0 0 3px 0 rgba(0, 0, 0, .04);
  .tabs-view-wrapper {
    .tabs-view-item {
      display: inline-block;
      position: relative;
      cursor: pointer;
      height: 25px;
      line-height: 25px;
      border: 1px solid #d8dce5;
      color: #495060;
      background: #fff;
      padding: 0 8px;
      font-size: 12px;
      margin-left: 5px;
      margin-top: 4px;
      &:first-of-type {
        margin-left: 15px;
      }
      &:last-of-type {
        margin-right: 15px;
      }
      &.active {
        background-color: #42b983;
        color: #fff;
        border-color: #42b983;
        &::before {
          content: '';
          background: #fff;
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          position: relative;
          margin-right: 2px;
        }
      }
    }
  }
  .contextmenu {
    margin: 0;
    background: #fff;
    z-index: 3000;
    position: absolute;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, .3);
    li {
      margin: 0;
      padding: 7px 16px;
      cursor: pointer;
      &:hover {
        background: #eee;
      }
    }
  }
}
</style>

<style lang="scss">
//reset element css of el-icon-close
.tabs-view-wrapper {
  .tabs-view-item {
    .el-icon-close {
      width: 16px;
      height: 16px;
      vertical-align: 2px;
      border-radius: 50%;
      text-align: center;
      transition: all .3s cubic-bezier(.645, .045, .355, 1);
      transform-origin: 100% 50%;
      &:before {
        transform: scale(.6);
        display: inline-block;
        vertical-align: -3px;
      }
      &:hover {
        background-color: #b4bccc;
        color: #fff;
      }
    }
  }
}
</style>
